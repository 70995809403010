import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environment';
import { Observable } from 'rxjs';
import { DownloadService } from '#services/_download/download.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentRequestManualService {

    private _paymentRequestModelId;
    private _paymentRequestAniId;

    constructor(
        private _http: HttpClient,
        private _downloadService: DownloadService,
    ) {}

    getClientGroup(onlyActive?: boolean): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/client-groups?onlyActive=${onlyActive}`);
    }

    getEdi(subsidiaryCode, supplierCode): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial-integration/find-edi-configuration/${subsidiaryCode}/${supplierCode}`);
    }

    getSuppliers(): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-supplier-info`);
    }

    getProduct(): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial-integration/find-products`);
    }

    getAniById(id): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-ani-by-id/${id}`);
    }

    // getAniDocument(supplierCnpj, documentNumber): Observable<any> {
    //     return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-ani-document?supplierCnpj=${supplierCnpj}&documentNumber=${documentNumber}`);
    // }

    getAniDocument(params: { supplierCnpj?: string, startDate?: string, endDate?: string, documentNumber?: string }): Observable<any> {
        let httpParams = new HttpParams();

        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                httpParams = httpParams.set(key, value);
            }
        });

        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-ani-document`, { params: httpParams });
    }

    getCostCenter(): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-all-cost-centers`);
    }

    getAllModalities(): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-all-operation-modalities`);
    }

    getAllExpenses(): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-all-expenses`);
    }

    getAllDocTypes(): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-all-document-types`);
    }

    getAllCurrencies(): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-all-currencies`);
    }

    getAllStatus(): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-all-status`);
    }

    getAllSuppliers(): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-all-suppliers`);
    }

    getUserSuppliers(): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-suppliers`);
    }

    getSuppliersCnpj(supplierCode): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-supplier-cnpj/${supplierCode}`);
    }

    getPaymentRequestManual(request): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-by-filter`, request);
    }

    getDetailsById(id): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-by-id/${id}`);
    }

    getDetailsByImp(subsidiary, imp): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/find-imp-details/${subsidiary}/${imp}`);
    }

    getUsersList(profileDescription, onlyActive): Observable<any> {
        return this._http.get(`${environment.endpoints.adminService}/users?profileDescription=${profileDescription}&onlyActive=${onlyActive}`);
    }

    getConfiguration(id): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial-integration/find-configuration/${id}`);
    }

    getGed(subsidiaryCode): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial-integration/find-ged-folder/${subsidiaryCode}`);
    }

    getProjectAccount(id, configurationCode): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial-integration/find-project-account/${id}/${configurationCode}`);
    }

    getHistory(id): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/history/${id}`);
    }

    getPaymentRequestAniId() {
        return this._paymentRequestAniId;
    }

    getPaymentRequestModelId() {
        return this._paymentRequestModelId;
    }

    getApprovalList(paymentId): Promise<any> {
        return this._http.put(`${environment.endpoints.supplyEngineService}/manual-financial/approval-list/${paymentId}`, null).toPromise();
    }

    setPaymentRequestModelId(paymentRequestModelId): void {
        this._paymentRequestModelId = paymentRequestModelId;
    }

    setPaymentRequestAniId(paymentRequestAniId): void {
        this._paymentRequestAniId = paymentRequestAniId;
    }

    createPayment(request): Observable<any> {
        return this._http.post(`${environment.endpoints.supplyEngineService}/manual-financial-integration/create-payment`, request);
    }

    saveDetails(data, files?): Observable<any> {
        const request = new FormData();

        request.append('request',
            new Blob([JSON.stringify(data)], {
                type: 'application/json',
            })
        );

        if(files) {
            for (const file of files) {
                request.append('files', file, file.name);
            }
        }

        return this._http.post(`${environment.endpoints.supplyEngineService}/manual-financial/create`, request);
    }

    updateResponsibleUser(users) {
        return this._http.put(`${environment.endpoints.supplyEngineService}/manual-financial/assign-responsible-user`, users, {});
    }

    cancelSp(id, request) {
        return this._http.put(`${environment.endpoints.supplyEngineService}/manual-financial/cancel/${id}`, request, { observe: 'response', responseType: 'text' });
    }

    concludeSp(id) {
        return this._http.put(`${environment.endpoints.supplyEngineService}/manual-financial/conclude/${id}`, {});
    }

    reviewSp(id, reasonForReview) {
        return this._http.put(`${environment.endpoints.supplyEngineService}/manual-financial/review/${id}`, {reasonForReview});
    }

    updateSp(id, data, files) {
        const request = new FormData();

        request.append('updateRequest',
            new Blob([JSON.stringify(data)], {
                type: 'application/json',
            })
        );

        for (const file of files) {
            request.append('files', file, file.name);
        }

        return this._http.put(`${environment.endpoints.supplyEngineService}/manual-financial/update/${id}`, request, { observe: 'response', responseType: 'text' });
    }

    downloadFile(id, file, fileName) {
        const options = this._downloadService.getSourceOptions();
        const url = this._http.get(`${environment.endpoints.supplyEngineService}/manual-financial/download/${id}?name=${file?.name}&aliasName=${file?.aliasName}`, options);
        return this._downloadService.downloadFrom(url, fileName);
    }

    downloadAllFiles(id, fileName) {
        return this._downloadService.syncExport(`${environment.endpoints.supplyEngineService}/manual-financial/download/${id}`, [], 'Solicitação de Pagamento Manual', fileName);
    }
}
