import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environment';
import { Router, ActivatedRoute } from '@angular/router';
import { IExpense, ISupplierDocument } from '../payment-request/interfaces/payment-request.interface';

@Injectable({
    providedIn: 'root',
})
export class PaymentRequestService {


    private data$: BehaviorSubject<Object> = new BehaviorSubject(null);
    manualModalSubject: Subject<any> = new Subject();

    constructor(private router: Router, private route: ActivatedRoute, private _http: HttpClient) {}

    onNavigateByTab(tabCode:string){

        if(tabCode === 'associations'){
            this.router.navigate(['cadastro/integracoes/' + tabCode], { relativeTo: this.route })
        }else{
            this.router.navigate(['cadastro/integracoes'], {relativeTo: this.route, queryParams: {account: tabCode}})
        }
    }

    getSuppliersDocuments(filter): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/supply`, filter);
    }

    getSupplierDocumentById(id: string): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/supply/${id}`);
    }

    getSubsidiaries(): Observable<any> {
        return this._http.get(`${environment.endpoints.domainService}/subsidiaries`);
    }

    getDetailDocument(id:string): Observable<any> {
        return this._http.get(`${environment.endpoints.importationService}/`);
    }

    getProcessNumber(processNumber:string): Observable<any> {
        return this._http.get(`${environment.endpoints.importationService}/imps/integration-by-type/${processNumber}/IMP`);
    }

    getCostCenter(): Observable<any> {
        return this._http.get(`${environment.endpoints.domainService}/profitCenter`);
    }

    getAccountsSuppliers(): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/supply/find-all`);
    }

    getOperationModality() {
        return [
            {
                name: "Compra e Venda Operação Própria",
                code: "COMPRA_E_VENDA_OPRACAO_PROPRIA"
            },
            {
                name: "Conta e Ordem",
                code: "CONTA_E_ORDEM"
            },
            {
                name: "Compra e Venda por Encomenda",
                code: "COMPRA_E_VENDA_ENCOMENDA"
            }
        ];
    }

    getStatusSuppliersDocument(): Observable<any> {
        return of([
            {
                name: 'A Revisar',
                code: 'REVIEW'
            },
            {
                name: 'Rejeitado',
                code: 'REJECTED'
            },
            {
                name: 'Em processamento',
                code: 'IN_PROCESSING'
            },
            {
                name: 'Processado',
                code: 'PROCESSED'
            },
            {
                name: 'Erro',
                code: 'ERROR'
            }
        ]);
    }

    getListToRejectionDocument(): Observable<any> {
        return of([
            {
                label: 'BPO',
                value: 'BPO'
            },
            {
                label: 'Não é NFS',
                value: 'Não é NFS'
            },
            {
                label: 'Faturamento errado',
                value: 'Faturamento errado'
            },
            {
                label: 'Admissão temporária',
                value: 'Admissão temporária'
            },
            {
                label: 'Outro',
                value: 'other'
            },
        ]);
    }

    getSuppliersDocumentType(): Observable<any> {
        return of([
            {code: 'GRU', name:'GRU'},
            {code: 'ND', name:'ND'},
            {code: 'NFS', name:'NFS'},
            {code: 'NFS_RET', name:'NFS RET'},
            {code: 'DACTE', name:'DACTE'}
        ]);
    }

    getConfigurationSupplierDocument(supplierOwner:string, documentType:string, configurationTypeTo:string): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/configuration/find-by-filter?documentType=${documentType}&configurationTypeTo=${configurationTypeTo}&supplierOwner=${supplierOwner}`);
    }

    getSpecificConfigurationSupplierDocument(operationModality:string, documentType:string, owner:string, subsidiaryCode:string, subsidiaryName:string, isBpo:boolean): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/configuration/find-specific-configuration?operationModality=${operationModality}&documentType=${documentType}&owner=${owner}&subsidiaryCode=${subsidiaryCode}&subsidiaryName=${subsidiaryName}&isBpo=${isBpo}`);
    }

    getExpensesSupplierDocument(supplierOwner:string): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/expense?supplierOwner=${supplierOwner}`);
    }

    getInternalExpensesSupplier(): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/expense/find-internal-expenses`);
    }

    getExpensesSupplier(supplierCode:string): Observable<any> {
        return this._http.get(`${environment.endpoints.supplyEngineService}/expense/find-all-supplier-expenses?supplierOwner=${supplierCode}`);
    }

    saveDocument(document: ISupplierDocument){
        return this._http.put(`${environment.endpoints.supplyEngineService}/supply/${document.id}`, document);
    }

    updateToProcess(idDocument){
        return this._http.put(`${environment.endpoints.supplyEngineService}/supply/send-erp/${idDocument}`, {});
    }

    confirmToRejection(idDocument, reason){
        return this._http.patch(`${environment.endpoints.supplyEngineService}/supply/reject-document/${idDocument}`, reason);
    }

    setFilters(data) {
        this.data$.next(data);
    }

    getFilters() {
        return this.data$.asObservable();
    }

    updateExpenseLink(account){
        return this._http.put(`${environment.endpoints.supplyEngineService}/expense/link/${account.id}`, account)
    }

    insertExpenseLink(account){
        return this._http.post(`${environment.endpoints.supplyEngineService}/expense/link`, account)
    }

    deleteExpenseLink(account){
        return this._http.delete(`${environment.endpoints.supplyEngineService}/expense/link/${account.id}`)
    }

    getRelationShipExpenses(supplierOwner:string){
        return this._http.get(`${environment.endpoints.supplyEngineService}/expense/find-relationship-expense?supplierOwner=${supplierOwner}`)
    }

    getExpensesBySupplier(supplier:string){
        return this._http.get(`${environment.endpoints.supplyEngineService}/expense/find-all-supplier-expenses?supplierOwner=${supplier}`);
    }

    saveExpense(expense:IExpense){
        return this._http.post(`${environment.endpoints.supplyEngineService}/expense`, expense);
    }

    deleteExpense(expense:IExpense){
        return this._http.delete(`${environment.endpoints.supplyEngineService}/expense/${expense.id}/${expense.type}`);
    }

    verifyExpenseHasLink(expense:IExpense){
        return this._http.get(`${environment.endpoints.supplyEngineService}/expense/has-link/${expense.id}/${expense.type}`, {observe: 'response'});
    }

    updateExpense(expense:IExpense){
        return this._http.put(`${environment.endpoints.supplyEngineService}/expense/${expense.id}/${expense.type}`, expense);
    }
}
